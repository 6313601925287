var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"coupon"},[_c('div',{staticClass:"wrap"},[_c('div',{staticClass:"form"},[_c('div',{staticClass:"form-content"},[_c('van-form',{ref:"form",on:{"submit":_vm.subSuccess}},[_c('ul',{staticClass:"form-ul"},[_c('li',[_c('van-field',{attrs:{"placeholder":"请输入兑换码","rules":_vm.rules.code,"name":"pattern","maxlength":16,"label":"兑换码："},on:{"blur":_vm.redeemChange},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}})],1),_c('li',[_c('van-field',{attrs:{"placeholder":"请输入手机号","maxlength":11,"rules":_vm.rules.phone,"label":"手机号：","type":"tel","disabled":_vm.phoneInputDisable},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1),_c('li',{staticClass:"tow-para"},[_c('van-field',{attrs:{"placeholder":"请输入验证码","maxlength":6,"rules":_vm.rules.captcha,"label":"验证码：","type":"number"},model:{value:(_vm.form.captcha),callback:function ($$v) {_vm.$set(_vm.form, "captcha", $$v)},expression:"form.captcha"}}),_c('div',{class:_vm.subBtnDisable
                    ? 'btn is-disable'
                    : _vm.btn.disable
                    ? 'btn is-disable'
                    : 'btn',on:{"click":_vm.getCode}},[_vm._v(" "+_vm._s(_vm.subBtnDisable ? '获取验证码' : _vm.btn.disable ? `${_vm.btn.num}s` : '获取验证码')+" ")])],1)])])],1),_c('div',{class:_vm.subBtnDisable ? 'btn-sub is-disable' : 'btn-sub',on:{"click":_vm.submit}},[_vm._v(" 激活 ")])]),_vm._m(0)]),_c('van-dialog',{staticClass:"cus-dialog",attrs:{"showConfirmButton":false,"closeOnClickOverlay":false},model:{value:(_vm.dialog.show),callback:function ($$v) {_vm.$set(_vm.dialog, "show", $$v)},expression:"dialog.show"}},[_c('h3',{staticClass:"title"},[_vm._v("温馨提示！")]),_c('div',{staticClass:"msg"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.dialog.msg)}}),(
          _vm.dialog.ulMsgShow &&
            _vm.dialog.redeCodeStatus == 2 &&
            _vm.dialog.gcAttribute == 3
        )?_c('ul',[_c('li',[_c('span',[_vm._v("充值商品：")]),_c('em',[_vm._v(_vm._s(_vm.dialog.goodsName))])]),_c('li',[_c('span',[_vm._v("充值账号：")]),_c('em',[_vm._v(_vm._s(_vm.dialog.account))])]),_c('li',[_c('span',[_vm._v("充值时间：")]),_c('em',[_vm._v(_vm._s(this.common.formatDate(_vm.dialog.rechargeTime, '{y}-{m}-{d}')))])])]):_vm._e(),(
          _vm.dialog.ulMsgShow &&
            _vm.dialog.redeCodeStatus === 2 &&
            _vm.dialog.gcAttribute != 3
        )?_c('ul',[_c('li',[_c('span',[_vm._v("兑换商品：")]),_c('em',[_vm._v(_vm._s(_vm.dialog.goodsName))])]),_c('li',[_c('span',[_vm._v("手机号码：")]),_c('em',[_vm._v(_vm._s(_vm.dialog.account))])]),_c('li',[_c('span',[_vm._v("兑换时间：")]),_c('em',[_vm._v(_vm._s(this.common.formatDate(_vm.dialog.excTime, '{y}-{m}-{d}')))])])]):_vm._e(),(_vm.dialog.ulMsgShow && _vm.dialog.redeCodeStatus === 3)?_c('ul',[_c('li',[_c('span',[_vm._v("过期时间：")]),_c('em',[_vm._v(_vm._s(this.common.formatDate(_vm.dialog.expireTime, '{y}-{m}-{d}')))])])]):_vm._e()]),_c('div',{staticClass:"confirm",on:{"click":_vm.dialogSure}},[_vm._v("确认")])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rule"},[_c('h2',[_c('span',[_vm._v("兑换规则")])]),_c('ul',[_c('li',[_vm._v("1、请详细核对兑换的手机号，必须11位数正确的手机号码。")]),_c('li',[_vm._v("2、请在有效期内使用，过期将无法使用，不退货不补偿。")]),_c('li',[_vm._v(" 3.如遇问题，可拨打客服热线："),_c('a',{attrs:{"href":"tel:4006627299"}},[_vm._v("4006627299")])])])])
}]

export { render, staticRenderFns }